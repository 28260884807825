.div_main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: serif;
  font-size: 20px;
  font-weight: 600;
}
.th {
  padding: 34px 0px !important;
}
.w {
  width: 200px !important;
}
.wh {
  width: 94px !important;
}
.r {
  text-align: left !important; 
  margin: 0px 0px 0px 10px !important;
}
.re {
  font-size: 18px !important;
  font-weight: 500 !important;
}
.f_w{
    font-weight: bold !important;
}