.Active{
    background-color: red !important;
}
.InActive{
    background-color:#0473e6 !important;
}
.spiner{
    display: flex;
    justify-content: center;
    align-items: center;
}