.promissory-note {
  padding: 50px;
  background-color: #fff;
  color: #000;
}

.header-title {
  text-align: right;
  font-weight: 600;
}

.header-subtitle {
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}

.note-title {
  text-align: center;
  margin-top: 50px;
  text-decoration: underline;
  font-weight: 400;
}

.note-content {
  margin-top: 30px;
}

.signatures {
  display: flex;
  flex-direction: column;
}

.signature-block {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.signature-details {
  display: flex;
  flex-direction: column;
}

.signature-field {
  display: flex;
  align-items: end;
}

.signature-box {
  border: 1px solid #000;
  width: 100px;
  height: 108px;
  margin-left: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000;
}

th,
td {
  border: 1px solid black;
}

section {
  margin-top: 10px;
}

h3 {
  text-align: center;
  font-weight: 600;
}

.left {
  text-align: left;
  margin-top: 20px;
  font-weight: 600;
  text-decoration: underline;
}

.main-div {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  color: #000;
}

.main-title {
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 20px;
}

.main-title2 {
  font-weight: 600;
  text-align: center;
}

.p-mt-10px {
  margin-top: 10px;
}

.p-mt-30px {
  margin-top: 30px;
}

.center-heading {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}