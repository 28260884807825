.kyc_header {
  background: #00d2ff !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px 13px 18px 24px !important;
}
.kyc_body {
  width: 100%;
  display: flex;
  .kyc_left_body {
    width: 20%;
    background-color: #f1e5e547;
    .kyc_img {
      display: flex;
      justify-content: center;
      border: 1px solid;
      width: 32%;
      height: 31px;
      border-radius: 21px;
      margin: 8px 0px 8px 46px;
    }
    ul {
        .list-border {
            border-bottom: 0.5px solid #e2dcdc;
            padding: 5px;
          }
          
          
          .list-border:last-child {
            border-bottom: none;
          }
          padding: 2px 0px 0px 15px;
      li {
        list-style: none;
        padding: 10px 0px 10px 0px !important;
       
      }
    }
  }
  .kyc_right_body {
    width: 80%;
    background-color: #f1e5e547;
    .kyc_btn{
        display: flex;
    margin: 11px 1px 4px 77px;
    gap: 11px;
    .btn{
        background-color: #00d2ff;
    color: black;
    }
    }
  }
}
