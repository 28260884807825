.main_div {

  .logo {
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
  }

  .de_tails {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .de_rt {
      width: 50%;
      margin: 0px 0px 0px 58px;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .de_span {
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
      }

    }

    .de_lt {
      width: 50%;
      margin: 0px 65px 0px 0px;
      display: flex;
      text-align: end;
      flex-direction: column;
      gap: 6px;
    }
  }
}

.de_spn {
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}

.sta {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  padding: 16px 0px;
  text-transform: capitalize;
}

.div_tab {
  width: 90%;
  margin: 0px 0px 0px 59px;
}

.div_tab tbody tr td.no-border {
  border: none;
}