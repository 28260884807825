.receipt_top {
  display: flex;
  width: 100%;
  border: 2px solid black;
  height: 100px;

  .top_first {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid black;
  }

  .top_second {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid black;
    flex-direction: column;

    .top_header {
      font-size: 25px;
      font-weight: 500;
    }
  }

  .top_third {
    flex-direction: column;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.body_reciept {
  border: 2px solid black;
  margin: 20px 0px 0px 0px;

  .body_table {
    border-bottom: 2px solid black;
    padding: 4px 0px 4px 0px;

    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
  }

  .body_data {
    display: flex;
    width: 100%;

    .body_table_data {
      width: 45%;
      border-right: 2px solid black;

      .table_data {
        display: flex;
        width: 100%;

        .data {
          width: 70%;
          padding: 5px 0px 5px 0px;
          border-right: 2px solid black;
          border-bottom: 2px solid black;
        }

        .data_tab {
          border-bottom: 2px solid black;
          width: 30%;
          padding: 5px 0px 5px 0px;
        }
      }
    }

    .data_img {
      height: 200px;
      display: flex;
      justify-content: end;
      width: 55%;

      .img_tag {
        border: 2px solid black;
        margin: 5px 6px -9px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px 47px;
      }
    }
  }

  .address_details {
    border-top: 2px solid black;
    border-bottom: 3px solid black;
    font-size: 20px;
    font-weight: 500;
  }

  .addres {
    display: flex;
    width: 100%;
    border-top: 3px solid black !important;
    border-bottom: 3px solid black;
    font-size: 20px;
    font-weight: 500;

    .income {
      width: 50%;
      border-right: 2px solid black;
    }

    .income_d {
      width: 50%;
    }
  }

  .adres_add {
    display: flex;
    width: 100%;
  }
}

.emi_table {
  display: flex;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  border: 2px solid black;

  .emi_data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.emi_table_data {
  display: flex;
  width: 100%;
  border: 1px solid black;
}

.reypayment {
  display: flex;
  width: 100%;
  font-size: 19px;
  font-weight: 500;
  border: 2px solid black;

  .data {
    width: 23%;
    border-right: 2px solid black;
  }
}

.reypayment_body {
  display: flex;
  width: 100%;
  font-size: 15px;
  border: 1px solid black;

  .data {
    width: 23%;
    border-right: 2px solid black;
  }
}

.search_bar {
  display: flex;
  justify-content: space-between;
  margin: 15px 17px -18px 14px;
}

//searchbar 
@media (max-width: 768px) {
  .search_bar {
    display: flex;
    flex-direction: column;
  }

  .search-inner {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .theform_text {
    font-size: 14px;
  }
}

@media (min-width:768px) {
  .search_bar {
    display: flex;
    justify-content: space-between;
    margin: 15px 17px -18px 14px;
  }

  .search-inner {
    display: flex;
    flex-direction: row !important;
    width: 55%;
    justify-content: space-between;
  }
}

.btn_add {
  display: flex;
  justify-content: end;
  margin: 13px 0px 0px 0px;
}

//theform_text