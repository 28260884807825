.ac_header {
  margin: 10px 0px 10px;
  span {
    margin: 0px 24px;
    font-size: 19px;
    font-weight: 500;
  }
}

.border_bottom {
  border-bottom: 1px solid black;
}

.ac_date {
  margin: 16px 20px;
}

.ac_list {
  padding: 0px 21px 0px 21px;
}

.new_da{
  font-size: 24px !important;
  margin-top: 18px;
  width: 90% !important;
  padding: 0px 0px 0px 80px !important;
}

.ac_textarea {
  padding: 34px 20px;
}
.assets_data {
  display: flex;
  justify-content: space-around;
}
.total_assets_data {
  display: flex;
  justify-content: space-around;
  font-weight: 500;
  padding: 6px 0px;
}
.assets_head {
  margin: 10px 0px;
}
.liabilities_data {
  background: #303c50;
  color: white;
  font-weight: 500;
  padding: 3px 2px;
}
.assets_to {
  display: flex;
  justify-content: space-around;
  font-weight: 700;
  color: white;
  background: #303c50;
  padding: 3px 0px;
}
.trade_data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: larger;
  font-weight: 500;
  text-transform: capitalize;
}
.p_l_data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  text-transform: capitalize;
}
.ledger_data{
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
.dr_span{
  padding: 6px 0px 0px 28px;
}
.dr_add{
  display: flex;
  margin: 0px 100px 0px;
}
.ad_row{
  margin: 42px 0px 26px 0px;
}
.draw{
  display: flex;
  justify-content: space-around;
}
.to_cash{
  margin: 10px 0px;
}
.to_cas{
  margin: 34px 0px 0px;;
}
.to_pur{
  margin: 0px 0px 0px;
}
.add_bt{
  display: flex;
  justify-content: end;
}
.ac_type{
  display: flex;
  gap: 26px;
}
.f_lable{
  padding: 10px 0px 0px;
  font-weight: 500;
}